//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'
import { Link as LinkS} from 'react-scroll'

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height:  ${({vendor}) => (vendor ? '1200px': '1000px')};
    position: relative;
    z-index: 0;

    :before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 0;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    overflow: hidden;
`;

export const VideoBg = styled.img`
    width: 100%;
    height:  100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: ${({vendor}) => (vendor ? '#f3f2f2' : '#FFF')};
`;

export const HeroContent = styled.div`
    z-index: 0;
    max-width: 1200px;
    position: absolute;
    margin-top:  ${({vendor}) => (vendor ? '0px': '-200px')};
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 380px){
        padding: 0;
    }
`;

export const HeroH1 = styled.h1`
    color: ${({green}) => (green ? '#1EB445' : '#FFF')};
    margin-bottom: 25px;
    font-size: 48px;
    text-align: center;
    font-weight: 400;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #1EB445;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
    max-width: 600px;
    
    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`;

export const HeroSubP = styled.p`
    color: #1EB445;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
    max-width: 380px;
    
    @media screen and (max-width: 768px){
        font-size: 24px;
    }

    @media screen and (max-width: 480px){
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const TivosGreen = styled.h1`
    color: #1EB445; 
`;


export const VendorButton = styled.button`
    border-radius : 50px;
    background: #1EB445;
    white-space: nowrap;
    padding: 12px 30px;
    outline: none;
    border: solid 2px #1EB445;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
    width: 100%;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #1EB445;
        border: solid 2px #FFF;
    }
`;

export const VideoPres = styled.div`
    display: ${({ show }) => (show ? 'block' : 'none')}};
    position: absolute;
    z-index: 11;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0,0,0,.7);
    padding: 2% 1% 2% 4%;

    @media screen and (max-width: 900px){
        width: 90%;
        height: 400px;
        left: 35px;
    }

    @media screen and (max-width: 550px){
        width: 95%;
        height: 100%;
        left: 10px;
    }

`;

export const CloseButton = styled(LinkS)`
    position:relative;
    border-radius : 10px;
    background: #1EB445;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 11;
    border: solid 1px #1EB445;
    width: 10%;
    top: 95%;
    left: 45%;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #1EB445;
        border: solid 1px #1EB445;
    }
`;
