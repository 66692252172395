//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React , {useState} from 'react'
//import { md5 } from "blueimp-md5";
import {
    LoginContainer,
    LoginWrapper,
    LoginInfo,
    Logo,
    Label,
    Input,
    LoginImage,
    LoginRow,
    LoginColumn1,
    LoginColumn2,
    GlobalStyle,
    FormPopup,
    PopupTitle,
    PopupMessage,
    FormButton,
    ButtonFinalSection,
    FormItemLSub,
    FormCheckBox,
    FormLinkPage
} from './LoginElements'
import img3 from "../../images/Reset password-bro.svg";
import logo from "../../images/tivos-dark-logo.png";
import { Button } from '../ButtonElement'
import { savePassword } from '../../Connections/GeneralConn'
const md5 = require("blueimp-md5");

//=====================================================
//    Variables Globales
//=====================================================

    var errorMessage;

//=====================================================

const CreatePassword = () => {
    //==========================================================
    //    Chat Intercom
    //==========================================================

    window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "d7vcqtjn"
    };
        // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/d7vcqtjn'
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/d7vcqtjn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

    //==========================================================
    //    Comunicacion con Intercom
    //==========================================================
    
    const params = new URLSearchParams(document.location.search);
    const email = params.get("email");
    
    //=======================================================
    //    UseStare Variables
    //=======================================================

    const [showP, setShowP] = useState(false);
    const [showP1, setShowP1] = useState(false);
    const [blur, setBlur] = useState('');

    //=========================================================
    //    Popup manager
    //=========================================================

    function closePopup(){

        setShowP(!showP)
        setBlur(!blur)

    };

    //=========================================================

    //=========================================================
    //    Go to Login
    //=========================================================

    function login(){

        window.location = '/login'

    };

    //=========================================================

    //=========================================================
    //    Login Process
    //=========================================================

    function confirmPassword(){
        let email = document.getElementById('email').value;
        let pwd = (document.getElementById('password').value);
        let pwdV = (document.getElementById('confirmPassword').value);
        let priv = document.getElementById('privacidadCheck').checked;

        if(priv){
            if(email == '' || pwd == '' || pwdV == ''){
                errorMessage = 'Por favor llena todos los campos'
                setShowP(!showP)
                setBlur(!blur)    
            }else{
                if(pwd == pwdV){
                    let data = JSON.stringify({
                        userName: email,
                        password: md5(pwd)
                    });

                    savePassword(data,(response)=>{
                        
                        if(response.data[1] == 1){
                            errorMessage = 'Correo electrónico inválido. Intenta nuevamente.';
                            setBlur(!blur)
                            setShowP(!showP);
                        }else{
                            errorMessage = 'La contraseña ha sido creada exitosamente.';
                            setBlur(!blur)
                            setShowP1(!showP1);                    
                        }
            
                    })
                }else{
                    errorMessage = 'Las contraseñas no coinciden'
                    setShowP(!showP)
                    setBlur(!blur)
                }
            }
        }else{
            errorMessage = 'Por favor, acepta los Términos y Condiciones.'
            setShowP(!showP)
            setBlur(!blur)
        }

    }
    
    //=========================================================

    return (
        <>
        <GlobalStyle/>

        <LoginContainer>

            <FormPopup show={showP}>
                <PopupTitle>¡Hemos encontrado un error!</PopupTitle>
                <PopupMessage>{errorMessage}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={closePopup}>Cerrar</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <FormPopup show={showP1}>
                <PopupTitle>¡FELICIDADES!</PopupTitle>
                <PopupMessage>{errorMessage}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={login}>Ir al Login</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <LoginWrapper blur={showP}>
                <LoginRow>
                    <LoginColumn1>
                        <LoginInfo>
                            <Logo src={logo}></Logo>
                            <Label>Crea tu contraseña</Label>
                            <Input placeholder='Correo electronico*' type="email" id="email"></Input>
                            <Input placeholder='Contraseña*' type="password" id="password"></Input>
                            <Input placeholder='Confirma tu contraseña*' type="password" id="confirmPassword"></Input>
                            <Button margin primary dark medium onClick={confirmPassword}>Guardar Contraseña</Button>
                        </LoginInfo>
                        <FormItemLSub><FormCheckBox type="checkbox" id='privacidadCheck'></FormCheckBox>Acepta nuestros <br></br><FormLinkPage to="/TerminosCondiciones">T&eacute;rminos y Condiciones.</FormLinkPage></FormItemLSub>
                    </LoginColumn1>
                    <LoginColumn2>
                        <LoginImage src={img3}></LoginImage>
                    </LoginColumn2>
                </LoginRow>
            </LoginWrapper>
        </LoginContainer>
        </>
    )
}

export default CreatePassword