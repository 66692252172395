//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import { 
    SimuladorContainer, 
    SimuladorH1, SimuladorPH, 
    SimuladorWrapper, SimuladorRow,
    SimuladorForm, Column1, Column2,
    SimuladorH2, 
    SimuladorSubTitulo, 
    SimuladorValorLetra, 
    SimuladorRango, 
    SimuladorSummary,
    MontoArrendamiento,
    MontoArrendamientoNumero,
    Division,
    PagosNumero,
    PagosNumeroLabel,
    PagoLetra,
    PagosWrapper,
    PagoCantidad//,
    //SubText 
}     
from './SimuladorElements'
import { Button } from '../ButtonElement'

const goToPreRegistro = () => {
    

    window.location = "./Solicitud"
}

const erase = () => {
    document.getElementById("monto").setAttribute("value", 4000000);
    document.getElementById("plazo").setAttribute("value", 36);
    document.getElementById("anticipo").setAttribute("value", 15);
    document.getElementById("residual").setAttribute("value", 15);
}

const simulador = () => {

    let monto = document.getElementById("monto").value;
    let plazo = document.getElementById("plazo").value;
    let anticipo = document.getElementById("anticipo").value;
    let residual = document.getElementById("residual").value;
    
    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    
    // var tirMin = 27.5/100;
    // var tirMax = 37/100;
    var tasaMin = 2.17/100;
    var tasaMax = 3.08/100;
    var credito = Math.ceil(monto/1.16);
    var aforoAmount = Math.ceil(credito*anticipo/100);
    var valorAmount = Math.ceil(credito*residual/100);
    var inversion = credito - aforoAmount;
    var payMax = Math.ceil((inversion-(valorAmount/Math.pow(1+tasaMax,plazo)))*(tasaMax/(1-Math.pow(1+tasaMax,plazo*-1))));
    var payMin = Math.ceil((inversion-(valorAmount/Math.pow(1+tasaMin,plazo)))*(tasaMin/(1-Math.pow(1+tasaMin,plazo*-1))));
    
    
    document.getElementById('montoFormateado').innerHTML = dollarUS.format(monto)
    document.getElementById('plazoFormateado').innerHTML = plazo + ' meses.'
    document.getElementById('anticipoFormateado').innerHTML = anticipo + ' %'
    document.getElementById('residualFormateado').innerHTML = residual + ' %'
    
    
    document.getElementById('MontoArrendamiento').innerHTML = dollarUS.format(monto)
    document.getElementById('Plazo').innerHTML = plazo

    document.getElementById('PagoMaximo').innerHTML = dollarUS.format(payMax)
    document.getElementById('PagoMinimo').innerHTML = dollarUS.format(payMin)

    localStorage.setItem('monto', monto)
    localStorage.setItem('plazo', plazo)
    localStorage.setItem('anticipo', anticipo)
    localStorage.setItem('residual', residual)


}

const Simulador = () => {
    return (
        
        <>
        <SimuladorContainer id="simulador" onMouseOver={erase}>
            <SimuladorH1>Utiliza el Simulador</SimuladorH1>
            <SimuladorPH>Juega con nuestro simulador para calcular el plan que más le convenga a tu empresa.</SimuladorPH>
            <SimuladorWrapper>
                <SimuladorRow>
                    <Column1>
                        <SimuladorForm>
                            <SimuladorH2>Tu Solicitud</SimuladorH2>

                            <SimuladorSubTitulo>Valor del Activo</SimuladorSubTitulo>
                            <SimuladorValorLetra id="montoFormateado">$4,000,000.00</SimuladorValorLetra>
                            <SimuladorRango id="monto" type="range" min="500000" max="20000000" step="250000" onChange={simulador}></SimuladorRango>

                            <SimuladorSubTitulo>Elige tu plazo</SimuladorSubTitulo>
                            <SimuladorValorLetra id="plazoFormateado">36 meses.</SimuladorValorLetra>
                            <SimuladorRango id="plazo" type="range" min="24" max="60" step="12" onChange={simulador}></SimuladorRango>

                            <SimuladorSubTitulo>Anticipo</SimuladorSubTitulo>
                            <SimuladorValorLetra id="anticipoFormateado">15 %</SimuladorValorLetra>
                            <SimuladorRango id="anticipo" type="range" min="5" max="25" step="5" onChange={simulador}></SimuladorRango>

                            <SimuladorSubTitulo>Valor Residual</SimuladorSubTitulo>
                            <SimuladorValorLetra id="residualFormateado">15 %</SimuladorValorLetra>
                            <SimuladorRango id="residual" type="range" min="5" max="25" step="5" onChange={simulador}></SimuladorRango>
                        </SimuladorForm>
                    </Column1>
                    <Column2>
                        <SimuladorSummary>
                        <Button primary dark marginB onClick={goToPreRegistro}>Iniciar Solicitud</Button>
                            <SimuladorH2>Resumen</SimuladorH2>
                            <Division></Division>
                            <MontoArrendamiento>Monto arrendamiento</MontoArrendamiento>
                            <MontoArrendamientoNumero id="MontoArrendamiento">$4,000,000.00</MontoArrendamientoNumero>
                            <Division></Division>
                            <PagosNumero id="Plazo">36</PagosNumero><PagosNumeroLabel>Pagos mensuales de:</PagosNumeroLabel>
                            <PagosWrapper>
                                <PagoLetra>Pago Máximo</PagoLetra>
                                <PagoCantidad id="PagoMaximo">$113,772</PagoCantidad>
                            </PagosWrapper>
                            <PagosWrapper>
                                <PagoLetra>Pago Minimo</PagoLetra>
                                <PagoCantidad id="PagoMinimo">$127,816</PagoCantidad>
                            </PagosWrapper>
                            {/* <SubText>*Cifras incluyen IVA</SubText> */}
                            {/* <Button primary="true" dark margin="false" onClick={goToPreRegistro}>Iniciar Solicitud</Button> */}
                        </SimuladorSummary>
                    </Column2>
                </SimuladorRow>
            </SimuladorWrapper>
        </SimuladorContainer>
        </>
    )
}

export default Simulador

