//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
// DR - General Changes
//=====================================================//

import React, {useState} from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'
import Sidebar from '../SidebarAlt'
import Navbar from '../NavbarAlt'
import Footer from '../Footer'
import {
    FormularioContainer,
    FormularioWrapper,
    FormTitle,
    FormItemL,
    FormInput,
    FormSelect,
    FormOption,
    FormButton,
    FormItemLSub,
    FormCheckBox,
    FormLinkPage,
    FormSubText,
    ButtonSection,
    // Body,
    ProgressBar,
    FormularioSplash,
    FormTitleSplash,
    FormSubTitleSplash,
    ButtonFinalSection,
    BlackSubText,
    FormPopup,
    PopupTitle,
    PopupMessage,
    LinkedInWrapper,
    LinkedInIcon
}
from './FormulariosElements';
import { SocialIcons,SocialIconLink } from '../Footer/FooterElements';
import { FaLinkedin } from 'react-icons/fa';
import { Button } from "../ButtonElement";
import validator from 'validator'
// import { type } from '@testing-library/user-event/dist/type'
import LinkedIn from '../../images/linkedIn-1.png'
import {
    appExtConnLead // ,
    // tivosApi
} 
from '../../Connections/GeneralConn'
import { useSearchParams } from "react-router-dom";

var nombre, 
    correo, 
    errorMessage, 
    captionEmpresa,
    empresaRfc, 
    clienteBroker, 
    descripcionClienteBroker1,
    descripcionClienteBroker2,
    descripcionClienteBroker3;

const PreRegistroForm = () => {

//==========================================================
//  Chat Intercom
//==========================================================

    // window.intercomSettings = {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "d7vcqtjn"
    // };
    //     // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/d7vcqtjn'
    // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/d7vcqtjn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

//==========================================================
//  Constantes para la navegacion
//==========================================================
        
const [show0, setShow0] = useState(true);
const [show1, setShow1] = useState(false);
const [show2, setShow2] = useState(false);
const [show3, setShow3] = useState(false);
const [show4, setShow4] = useState(false);
const [show5, setShow5] = useState(false);
const [show6, setShow6] = useState(false);
const [show7, setShow7] = useState(false);
const [show8, setShow8] = useState(false);
const [show9, setShow9] = useState(false);
const [show10, setShow10] = useState(false);
const [show11, setShow11] = useState(false);
const [show12, setShow12] = useState(false);
const [show13, setShow13] = useState(false);
const [show14, setShow14] = useState(false);
const [showT1, setShowT1] = useState(true);
const [showT2, setShowT2] = useState(true);
const [showP, setShowP] = useState('');
const [showP1, setShowP1] = useState('');
const [showSub, setShowSub] = useState(true);
const [showSub1, setShowSub1] = useState(true);
const [showSub2, setShowSub2] = useState(true);

// const [emailError, setEmailError] = useState('')


const [searchParams, setSearchParams] = useSearchParams();

const params = searchParams.get("cuentasOk") ?? false;

sessionStorage.setItem('IsCuentasOk',params)

window.onload = function(){
    if(params){ if(!showSub2){setShowSub2(showSub2)} }else{ if(showSub2){setShowSub2(!showSub2)} }
}
//========================================================
//  Muestra opcion si es cliente de CuentasOK
//========================================================

// function clientOptions(e){
//     var evt = e.target.value

//     if(evt == 2 ){
//         if(showSub2){setShowSub2(!showSub2)}

//     }
// }

//========================================================
//  Muestra opcion si Empresario tiene Broker
//========================================================

    function showOptions(e){
        var evt = e.target.value
        let placeholder = document.getElementById("recommendation")

        if(evt == 1 || evt == 2 || evt == 3 || evt == 4){
            switch(evt){
                case '1':
                    placeholder.setAttribute("placeholder","Me recomendó...")
                    if(showSub){setShowSub(!showSub)}
                    break;
                case '4':
                    placeholder.setAttribute("placeholder","Nombre de la Empresa o el ejecutivo...")
                    if(showSub){setShowSub(!showSub)}
                    break;
                default:
                    placeholder.setAttribute("placeholder","Nombre del Broker...")
                    if(showSub){setShowSub(!showSub)}
                    break;

            };
        }else{
            if(!showSub){setShowSub(!showSub)}
        }
    }

//========================================================
//  Funcion de validacion de campos
//========================================================

    function verifyFields(step,response) {

        if(step === 1){
            let userName = document.getElementById('userName').value,
                userLastName = document.getElementById('userLastName').value,
                userEmail = document.getElementById('userEmail').value,
                userPhone = document.getElementById('userPhone').value,
                reference = document.getElementById('reference').value,
                empresarioBroker; 

            if(sessionStorage.getItem('IsCuentasOk') === 'true'){ 
                empresarioBroker = 1
            }else{ 
                empresarioBroker = document.getElementById('empresarioBroker').value
            }
                
            if(userPhone.length > 10){
                errorMessage = 'El número de teléfono no es correcto'; 
                setShowP(!showP)
                return(response(false))
            }else if(userPhone.length === 10){
                if((userName === '' || userLastName === '' || userEmail === '' || userPhone === '' || empresarioBroker === '0')){
                    errorMessage = 'Para continuar, te pedimos llenar todos los campos'; 
                    setShowP(!showP)
                    return response(false)
                }else{
                    return response(true)
                }
            }else if(userPhone.length < 10){
                errorMessage = 'El número de teléfono no es correcto'; 
                setShowP(!showP)
                return(response(false))
            }

        }else if(step === 2){
            let nombreCliente = document.getElementById("nombreCliente").value,
                emailCliente = document.getElementById("emailCliente").value,
                telefonoCliente = document.getElementById("telefonoCliente").value
            
            if(telefonoCliente.length > 10){
                errorMessage = 'El número de teléfono no es correcto'; 
                setShowP(!showP)
                return(response(false))
            }else if (telefonoCliente.length === '10'){
                if(nombreCliente !== '' || emailCliente !== '' || telefonoCliente !== ''){ return response(true)}
                else{return response(false)}
            }else if (telefonoCliente.length < 10){
                errorMessage = 'El número de teléfono no es correcto'; 
                setShowP(!showP)
                return response(false)
            }
        }else if(step === 3){
            let tipoPersona = document.getElementById("tipoPersona").value

                if(tipoPersona === '0') { return response(false)}
                else{return response(true)}

        }else if(step === 4){
            let nombreEmpresa = document.getElementById("nombreEmpresa").value

                if(nombreEmpresa !== ''){ return response(true)}
                else{return response(false)}

        }else if(step === 6){
            let antiguedadNegocio = document.getElementById("antiguedadNegocio").value

            if(antiguedadNegocio === '0'){ return response(false)}
            else{return response(true)}

        }else if(step === 5){
            let rfcEmpresa = document.getElementById("rfcEmpresa").value

            if(rfcEmpresa !== ''){ return response(true)}
            else{return response(false)}

        }else if(step === 7){
            let empresaSector = document.getElementById("empresaSector").value

            if(empresaSector === '0'){ return response(false)}
            else{return response(true)}

        }else if(step === 8){
            let principalCliente = document.getElementById("principalCliente").value

            if(principalCliente === 0){ return response(false)}
            else{return response(true)}

        }else if(step === 9){
            let ventasTotales = document.getElementById("ventasTotales").value

            if(ventasTotales === '0'){ return response(false)}
            else{return response(true)}

        }else if(step === 10){
            let buroSituacion = document.getElementById("buroSituacion").value

            if(buroSituacion === '0'){ return response(false)}
            else{return response(true)}

        }else if(step === 11){
            let tipoArrendamiento = document.getElementById("tipoArrendamiento").value

            if(tipoArrendamiento === '0'){ return response(false)}
            else{return response(true)}

        }else if(step === 12){
            let leaseBackAntiguedad = document.getElementById("leaseBackAntiguedad").value

            if(leaseBackAntiguedad === '0'){ return response(false)}
            else{return response(true)}
            
        }else if(step === 13){
            let valorActivo = document.getElementById("valorActivo").value

            if(valorActivo !== ""){ return response(true)}
            else{return response(false)}
        };
    };


//========================================================
//  Funcion para validar el numero de telefono
//========================================================

    const validate = (evt,step) => {
        let key = evt.nativeEvent.data;
        var evtType = evt.nativeEvent.inputType;
        var regex = /[0-9]|\./;
        
        if(evtType === 'deleteContentForward' || evtType === 'deleteContentBackward'){
            //no pasara nada
        }else{

            if( !regex.test(key) ) {  
                //userEmail = document.getElementById('userEmail').value,
                if(step === '1'){document.getElementById('userPhone').value = ''}
                else if(step === '2'){document.getElementById('telefonoCliente').value = ''}
                else if(step === '3'){document.getElementById('valorActivo').value = ''}
            }
        }
    }
    
    // function currencyFormat() {
    //     // console.log(response)
    //     // return(response)
    //     const options = { style: 'currency', currency: 'USD' };
    //     const numberFormat = new Intl.NumberFormat('en-US', options);
    //     let num = document.getElementById('valorActivo').value;
    //     document.getElementById('valorActivo').value = numberFormat.format(num)
    // }
//==========================================================
//  Funcion para validar el correo
//==========================================================

      const validateEmail = (e) => {
        var email = e.target.value

        if (validator.isEmail(email)) {
          //console.log('Valid Email :)')
        } else {
            errorMessage = 'Ingresa un correo válido.'; setShowP(!showP)
        }
      }
//==========================================================
//  Funcion para verificar el correo
//==========================================================

      function verifyEmail(step,response){
        var accept = document.getElementById('acceptEmail'), 
            cancel = document.getElementById('changeEmail');

            setShowP1(!showP1)
            
            accept.addEventListener('click',function(){
                setShowP1(showP1)
                response('ok')
            })

            cancel.addEventListener('click',function(){
                setShowP1(showP1)
                response('no')
            })

      };
//==========================================================
//  Variables el progress bar
//==========================================================
      
    var baseValue = -7, preguntaTotal = 12, value;


//==========================================================
//  Boton para atras
//==========================================================
      
    const backward = step => {
        let empresarioBroker = document.getElementById('empresarioBroker').value

        
        if(step === '1' || step === '0' || step === '14'){
            sessionStorage.clear()
        }else{
            value = baseValue * (preguntaTotal - step + 2) + '%'
            sessionStorage.setItem("stepValue",value)
        };
        
        switch(step){
            case 0:
                window.location = "/"
            break;
            case 1:
                setShow0(!show0)
                setShow1(!show1)
            break;
            case 2:
                setShow1(!show1)
                setShow2(!show2)
            break;
            case 3:
                if(empresarioBroker === '1'){
                    setShow3(!show3)
                    setShow1(!show1)
                }else{
                    setShow3(!show3)
                    setShow2(!show2)
                }
            break;
            case 4:
                setShow4(!show4)
                setShow3(!show3)
                if(!showT2){setShowT2(!showT2)}
                if(!showT1){setShowT1(!showT1)}
            break;
            case 5:
                setShow5(!show5)
                setShow4(!show4)
            break;
            case 6:
                setShow6(!show6)
                setShow5(!show5)
            break;
            case 7:
                setShow7(!show7)
                setShow6(!show6)
            break;
            case 8:
                setShow8(!show8)
                setShow7(!show7)
                break;
            case 9:
                setShow9(!show9)
                setShow8(!show8)
            break;
            case 10:
                setShow10(!show10)
                setShow9(!show9)
            break;
            case 11:
                setShow11(!show11)
                setShow10(!show10)
            break;
            case 12:
                setShow12(!show12)
                setShow11(!show11)
            break;
            case 13:
                setShow13(!show13)
                setShow11(!show11)
            break;
            case 14:
                window.location = "/"
            break;
            default:
                console.log('Nothing was selected')
            break;
        }

    };
    
//==========================================================
//  Boton para adelante
//==========================================================
    

    const forward = step => {
        let empresarioBroker = document.getElementById('empresarioBroker').value;
        let tipoPersona = document.getElementById('tipoPersona').value;
        let privacidadCheck = document.getElementById('privacidadCheck').checked;
        //let empresarioConBroker = document.getElementById('empresarioConBroker').value;
        let tipoArrendamiento = document.getElementById('tipoArrendamiento').value;
        let ok = true;
        // message = '';
        let IsCOKClient = sessionStorage.getItem('IsCuentasOk');

        correo = document.getElementById('userEmail').value;

        if(IsCOKClient === 'true'){empresarioBroker = 1}

        if(step === '14'){
            sessionStorage.clear()
        }else{
            value = baseValue * (preguntaTotal - step ) + '%'
            sessionStorage.setItem("stepValue",value)
        };

        verifyFields(step,(response) =>{
            if(!response){ ok = false}
        });

        switch(step){
            case 0:
                if (privacidadCheck){
                    setShow0(!show0)
                    setShow1(!show1)
                }else{
                    errorMessage = 'Para continuar, acepta nuestro Aviso de Privacidad y Términos y Condiciones'
                    setShowP(!showP)
                }
            break;
            case 1:
                if(!ok){}
                else{
                    verifyEmail(step,function(res){
                        if(res === 'ok'){
                            if(empresarioBroker === '1'){
                                setShow1(!show1)
                                setShow2(show2)    
                                setShow3(!show3)                                   
                            }else{
                                clienteBroker = 'Cliente' 
                                descripcionClienteBroker1 = '*No te preocupes, no contactaremos a tu cliente.'
                                descripcionClienteBroker2 = 'Esta información la necesitamos para que tu cliente tenga acceso a nuestra'
                                descripcionClienteBroker3 = 'plataforma y pueda subir los documentos requeridos.'
                                setShow1(!show1)
                                setShow2(!show2)
                                setShow3(show3)
                            };
                        };
                    })
                    
                }
            break;
            case 2:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow3(!show3)
                    setShow2(!show2)
                }
            break;
            case 3:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    if(tipoPersona === '1'){
                        setShow3(!show3)
                        setShow4(!show4)
                        if(showT1){setShowT1(!showT1)}else{setShowT1(showT1)}
                        captionEmpresa = 'Razón Social'
                    }else{
                        setShow3(!show3)
                        setShow4(!show4)
                        if(showT2){setShowT2(!showT2)}else{setShowT2(showT2)}
                        captionEmpresa = 'Nombre completo'
                    };
                };
            break;
            case 4:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else {
                    setShow4(!show4)
                    setShow5(!show5)
                    empresaRfc = 'RFC'
                }
            break;
            case 5:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else {
                    setShow5(!show5)
                    setShow6(!show6)
                }
            break;
            case 6:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow6(!show6)
                    setShow7(!show7)
                }
            break;
            case 7:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow7(!show7)
                    setShow8(!show8)
                }
            break;
            case 8:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow8(!show8)
                    setShow9(!show9)
                }
            break;
            case 9:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow9(!show9)
                    setShow10(!show10)
                }
            break;
            case 10:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow10(!show10)
                    setShow11(!show11)
                }
            break;
            case 11:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    if(tipoArrendamiento === '2'){
                        setShow11(!show11)
                        setShow12(!show12)
                    }else{
                        setShow11(!show11)
                        setShow13(!show13)

                        document.getElementById('valorActivo').value = localStorage.getItem('monto')
                    }
                }
            break;
            case 12:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow12(!show12)
                    setShow14(!show14)
                    nombre = document.getElementById("userName").value
                    correo = document.getElementById("userEmail").value
                    sessionStorage.setItem("stepValue",'-0%')
                    submitInformation();
                }
            break;
            case 13:
                if(!ok){errorMessage = 'Para continuar, te pedimos llenar todos los campos'; setShowP(!showP)}
                else{
                    setShow13(!show13)
                    setShow14(!show14)
                    nombre = document.getElementById("userName").value
                    correo = document.getElementById("userEmail").value
                    sessionStorage.setItem("stepValue",'-0%')
                    submitInformation();
                }
            break;
            case 14:
                setShow13(!show13)
            break;
            default:
                console.log('Nothing was selected')
            break;
        };
    };

    const percent = {
        main: sessionStorage.getItem("stepValue")
    }

//==========================================================
//  Popup manager
//==========================================================
    function closePopup(){
        setShowP(!showP)
    };

//==========================================================
//  Menu de navegacion
//==========================================================

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    scroll.scrollToTop();

//==========================================================
//  Mandar informacion a DB y ExA
//==========================================================

    const submitInformation = () => {

        var privacidadCheck = document.getElementById('privacidadCheck').checked,
            userName = document.getElementById('userName').value,
            userLastName = document.getElementById('userLastName').value,
            userEmail = document.getElementById('userEmail').value,
            userPhone = document.getElementById('userPhone').value,
            empresarioBroker = document.getElementById('empresarioBroker').options[document.getElementById('empresarioBroker').selectedIndex].text,
            empresarioBrokerId = parseInt(document.getElementById('empresarioBroker').value),
            reference = document.getElementById('reference').options[document.getElementById('reference').selectedIndex].text,
            referenceId = parseInt(document.getElementById('reference').value),
            recommendation = document.getElementById('recommendation').value,
            nombreCliente = document.getElementById('nombreCliente').value,
            emailCliente = document.getElementById('emailCliente').value,
            telefonoCliente = document.getElementById('telefonoCliente').value,
            tipoPersona = document.getElementById('tipoPersona').options[document.getElementById('tipoPersona').selectedIndex].text,
            tipoPersonaId = parseInt(document.getElementById('tipoPersona').value),
            nombreEmpresa = document.getElementById('nombreEmpresa').value,
            rfcEmpresa = document.getElementById('rfcEmpresa').value,
            antiguedadNegocio = document.getElementById('antiguedadNegocio').options[document.getElementById('antiguedadNegocio').selectedIndex].text,
            antiguedadNegocioId = parseInt(document.getElementById('antiguedadNegocio').value),
            empresaSector = document.getElementById('empresaSector').options[document.getElementById('empresaSector').selectedIndex].text,
            empresaSectorId = parseInt(document.getElementById('empresaSector').value),
            principalCliente = document.getElementById('principalCliente').options[document.getElementById('principalCliente').selectedIndex].text,
            principalClienteId = parseInt(document.getElementById('principalCliente').value),
            ventasTotales = document.getElementById('ventasTotales').options[document.getElementById('ventasTotales').selectedIndex].text,
            ventasTotalesId = parseInt(document.getElementById('ventasTotales').value),
            buroSituacion = document.getElementById('buroSituacion').options[document.getElementById('buroSituacion').selectedIndex].text,
            buroSituacionId = parseInt(document.getElementById('buroSituacion').value),
            tipoArrendamiento = document.getElementById('tipoArrendamiento').options[document.getElementById('tipoArrendamiento').selectedIndex].text,
            tipoArrendamientoId = parseInt(document.getElementById('tipoArrendamiento').value),
            leaseBackAntiguedad = document.getElementById('leaseBackAntiguedad').options[document.getElementById('leaseBackAntiguedad').selectedIndex].text,
            leaseBackAntiguedadId = parseInt(document.getElementById('leaseBackAntiguedad').value),
            valorActivo = document.getElementById('valorActivo').value,
            monto = localStorage.getItem('monto'),
            plazo = localStorage.getItem('plazo'),
            anticipo = localStorage.getItem('anticipo'),
            residual = localStorage.getItem('residual'),
            resultado = 0,
            data,
            slack,
            cuentasOkClient;

        if(sessionStorage.getItem('IsCuentasOk') === 'true'){
            cuentasOkClient = 'Si';
        }else{
            cuentasOkClient = 'No'
        }

        if(leaseBackAntiguedadId === 0){leaseBackAntiguedad = 'N/A'}
        if(antiguedadNegocioId === '1'){resultado = resultado + -1};
        if(principalClienteId === '3'){resultado = resultado + -1};
        if(ventasTotalesId === '1'){resultado = resultado + -1}else if(ventasTotalesId >= 4){resultado = resultado + 1};
        if(buroSituacionId === '4'){resultado = resultado + -1};
        if(tipoPersonaId === '3'){resultado = resultado + -4};

        let today = new Date();
        let day = today.getDate();
        let month = today.getMonth()+1;
        let year = today.getFullYear();

        let logDate = day+'/'+month+'/'+year

        if(empresarioBrokerId === 1){
            nombreCliente   = userName +" "+ userLastName;
            emailCliente    = userEmail;
            telefonoCliente = userPhone;
        };
            
        console.log(empresarioBrokerId)

        data = JSON.stringify({
            date:logDate,
            privacidadCheck : privacidadCheck,
            cuentasOkClient : cuentasOkClient,
            userName : userName,
            userLastName : userLastName,
            userEmail : userEmail,
            userPhone : userPhone,
            empresarioBroker : empresarioBroker,
            empresarioBrokerId : empresarioBrokerId,
            reference : reference,
            referenceId:referenceId,
            recommendation:recommendation,
            nombreCliente : nombreCliente,
            emailCliente : emailCliente,
            telefonoCliente : telefonoCliente,
            tipoPersona : tipoPersona,
            tipoPersonaId : tipoPersonaId,
            nombreEmpresa : nombreEmpresa,
            rfcEmpresa : rfcEmpresa,
            antiguedadNegocio : antiguedadNegocio,
            antiguedadNegocioId : antiguedadNegocioId,
            empresaSector : empresaSector,
            empresaSectorId : empresaSectorId,
            principalCliente : principalCliente,
            principalClienteId : principalClienteId,
            ventasTotales : ventasTotales,
            ventasTotalesId : ventasTotalesId,
            buroSituacion : buroSituacion,
            buroSituacionId : buroSituacionId,
            tipoArrendamiento : tipoArrendamiento,
            tipoArrendamientoId : tipoArrendamientoId,
            leaseBackAntiguedad : leaseBackAntiguedad,
            leaseBackAntiguedadId : leaseBackAntiguedadId,
            valorActivo : valorActivo,
            resultado : resultado,
            monto : monto,
            plazo : plazo,
            anticipo : anticipo,
            residual : residual
        })

        //External Log for redundance

        appExtConnLead(data,'Log',0,function(res){
            console.log(res)
        });

        // MONDAY INTEGRATION

        appExtConnLead(data,'monday',0,function(res){
            console.log(res)
        });

        //    Intercom in case it is a new broker
        // if(currentWorkingBroker == 2){
        //     appExtConnLead(data,'InterB',0,function(res){
        //         console.log(res)
        //     });    
        // }

        //    ExA

        appExtConnLead(data,'ExA',0,function(res){
            console.log(res)
        });

        //    Slack

        switch(resultado){
            case -1:
                slack = JSON.stringify({
                    score   : resultado,
                    nombre  : nombreEmpresa,
                    message : `
¡Tenemos un nuevo expediente con Score -1! Este expediente tendremos que revisarlo con cuidado :face_with_monocle:
*Ventas totales:* ${ventasTotales}
*Empresario/Broker:* ${empresarioBroker}
*Quien llenó el formulario:* ${userName}
*Score:* ${resultado}
*Monto a autorizar:* ${valorActivo}
*Lease Back / Activo Nuevo:* ${tipoArrendamiento}
*Antigüedad(en caso de Lease Back):* ${leaseBackAntiguedad}
`
                })
            break;
            case -2:
                slack = JSON.stringify({
                    score   : resultado,
                    nombre  : nombreEmpresa,
                    message : `
¡Tenemos un nuevo expediente con Score -2! Este expediente tendremos que revisarlo con cuidado :face_with_monocle:
*Ventas totales:* ${ventasTotales}
*Empresario/Broker:* ${empresarioBroker}
*Quien llenó el formulario:* ${userName}
*Score:* ${resultado}
*Monto a autorizar:* ${valorActivo}
*Lease Back / Activo Nuevo:* ${tipoArrendamiento}
*Antigüedad(en caso de Lease Back):* ${leaseBackAntiguedad}
`
                })
            break;
            case -3:
                slack = JSON.stringify({
                    score   : resultado,
                    nombre  : nombreEmpresa,
                    message : `
¡Tenemos un nuevo expediente con Score -3! Este expediente tendremos que revisarlo con cuidado :face_with_monocle:
*Ventas totales:* ${ventasTotales}
*Empresario/Broker:* ${empresarioBroker}
*Quien llenó el formulario:* ${userName}
*Score:* ${resultado}
*Monto a autorizar:* ${valorActivo}
*Lease Back / Activo Nuevo:* ${tipoArrendamiento}
*Antigüedad(en caso de Lease Back):* ${leaseBackAntiguedad}
`
                })
            break;
            case 0:
                slack = JSON.stringify({
                    score   : resultado,
                    nombre  : nombreEmpresa,
                    message : `
¡Tenemos un nuevo expediente con Score 0! :raised_hands: Recuerda revisar las tareas :white_check_mark:
*Ventas totales:* ${ventasTotales}
*Empresario/Broker:* ${empresarioBroker}
*Quien llenó el formulario:* ${userName}
*Score:* ${resultado}
*Monto a autorizar:* ${valorActivo}
*Lease Back / Activo Nuevo:* ${tipoArrendamiento}
*Antigüedad(en caso de Lease Back):* ${leaseBackAntiguedad}
`
                })
            break;
            case +1:
                slack = JSON.stringify({
                    score   : resultado,
                    nombre  : nombreEmpresa,
                    message : `
Big Deal Alert! Tenemos un nuevo expediente con Score +1 :fire: Recuerda revisar las tareas :white_check_mark:
*Ventas totales:* ${ventasTotales}
*Empresario/Broker:* ${empresarioBroker}
*Quien llenó el formulario:* ${userName}
*Score:* ${resultado}
*Monto a autorizar:* ${valorActivo}
*Lease Back / Activo Nuevo:* ${tipoArrendamiento}
*Antigüedad(en caso de Lease Back):* ${leaseBackAntiguedad}
`
                })
            break;
            default:
                slack = JSON.stringify({
                    score   : resultado,
                    nombre  : nombreEmpresa,
                    message : `
:x:. FYI: Acabamos de rechazar este expediente porque no cumplió con nuestros requisitos :nerd_face:
*Ventas totales:* ${ventasTotales}
*Empresario/Broker:* ${empresarioBroker}
*Quien llenó el formulario:* ${userName}
*Score:* ${resultado}
*Monto a autorizar:* ${valorActivo}
*Lease Back / Activo Nuevo:* ${tipoArrendamiento}
*Antigüedad(en caso de Lease Back):* ${leaseBackAntiguedad}
`
                })
            break;
        };

        appExtConnLead(slack,'Slack',0,function(res){
            console.log(res)
        });

        localStorage.clear()
        sessionStorage.clear()
    };
    
    const Capitalize = (e) => {
        let string = e.target.value;
        let elmId = e.target.id;
        let capitalizedString;
        if(elmId === 'rfcEmpresa') capitalizedString = string.toUpperCase();
        else capitalizedString = string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });

        document.getElementById(elmId).value = capitalizedString;
    };

    return (
        <>
        {/* <Body> */}
        <Sidebar isOpen = {isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/> 

        {/* DATOS PERSONALES */}
        
        <FormularioContainer>
            
            <FormPopup show={showP}>
                <PopupTitle>¡Hemos encontrado un error!</PopupTitle>
                <PopupMessage>{errorMessage}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small primary dark onClick={closePopup}>Cerrar</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <FormPopup show={showP1}>
                <PopupTitle>¿Es correcto tu correo?</PopupTitle>
                <PopupMessage>{correo}</PopupMessage>
                <FormButton>
                    <ButtonFinalSection>
                        <Button extraSmall margin="true" primary dark id="acceptEmail">Si</Button>
                        <Button extraSmall margin="true" primary dark id="changeEmail">No</Button>
                    </ButtonFinalSection>
                </FormButton>
            </FormPopup>

            <ProgressBar percent={percent}/>

            <FormularioSplash id="section0" show={show0}>
                <FormTitleSplash>¡Comencemos tu solicitud de arrendamiento!</FormTitleSplash>
                <FormSubTitleSplash>Solo te tomar&aacute; <BlackSubText>4 minutos.</BlackSubText></FormSubTitleSplash>
                <FormItemLSub show><FormCheckBox type="checkbox" id='privacidadCheck'></FormCheckBox>Acepta nuestro <FormLinkPage to="/AvisoPrivacidad">Aviso de Privacidad</FormLinkPage> y nuestros <FormLinkPage to="/TerminosCondiciones">T&eacute;rminos y Condiciones.</FormLinkPage></FormItemLSub>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(0)}>Atr&aacute;s</Button>
                    </ButtonSection>
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(0)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioSplash>
            
            <FormularioWrapper id="section1" show={show1}>
                <FormTitle>Queremos conocerte. </FormTitle>
                <FormItemL>¿Cu&aacute;l es tu Nombre?</FormItemL>
                    <FormInput id='userName' type="text" placeholder="Nombre..." onBlur={(e) => Capitalize(e)}></FormInput>
                <FormItemL>¿Cu&aacute;l es tu Apellido?</FormItemL>
                    <FormInput id='userLastName' type="text" placeholder="Apellido..." onBlur={(e) => Capitalize(e)}></FormInput>
                <FormItemL>¿Cuál es tu mail?</FormItemL>
                    <FormInput id='userEmail' type="email" placeholder="example@email.com" onBlur={(e) => validateEmail(e)}></FormInput>
                <FormItemL>¿Tu celular?</FormItemL>
                    <FormInput id='userPhone' type="tel" placeholder="123-456-7890" onChange={(e) => validate(e,1)}></FormInput>
                
                <FormItemL appear={showSub2}>¿Eres empresario o broker?</FormItemL>
                    <FormSelect show={showSub2} id="empresarioBroker">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>Empresario</FormOption>
                        <FormOption value={2}>Broker</FormOption>
                    </FormSelect>

                <FormItemL appear={showSub2}>¿Cómo nos conociste?</FormItemL>
                    <FormSelect show={showSub2} id="reference" onChange={(e) => showOptions(e)}>
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>Recomendación</FormOption>
                        <FormOption value={2}>Broker</FormOption>
                        <FormOption value={3}>SOC Asesores</FormOption>
                        <FormOption value={4}>Vendor Program</FormOption>
                        <FormOption value={5}>Megabrokers</FormOption>
                        <FormOption value={6}>LinkedIn</FormOption>
                        <FormOption value={7}>Google</FormOption>
                    </FormSelect>
                    <FormInput show={showSub} id='recommendation' type="text" onBlur={(e) => Capitalize(e)}></FormInput>

                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(1)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(1)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

        {/* DATOS DEL CLIENTE */}
            <FormularioWrapper id="section2" show={show2}>
                <FormTitle>Queremos conocer a tu {clienteBroker}.</FormTitle>
                <FormItemL>¿Cuál es el nombre de tu {clienteBroker}? </FormItemL>
                    <FormInput id="nombreCliente" type='text' placeholder='Nombre...' onBlur={(e) => Capitalize(e)}></FormInput>
                <FormItemL>¿Cuál es el mail de tu {clienteBroker}?</FormItemL>
                    <FormInput id="emailCliente" type="email" placeholder="example@email.com" onBlur={(e) => validateEmail(e)}></FormInput>
                <FormItemL>¿Cuál es el télefono de tu {clienteBroker}?</FormItemL>
                    <FormInput id="telefonoCliente" type="tel" placeholder="123-456-7890" onChange={(e) => validate(e,2)}></FormInput>
                <FormSubText>
                    {descripcionClienteBroker1}<br></br>
                    {descripcionClienteBroker2}<br></br>
                    {descripcionClienteBroker3}
                </FormSubText>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(2)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(2)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

        {/* DATOS DE LA EMPRESA */}
            <FormularioWrapper id="section3" show={show3}>
                <FormTitle>Queremos conocer tu Empresa.</FormTitle>
                <FormItemL>¿Qui&eacute;n solicita el arrendamiento?</FormItemL>
                    <FormSelect id="tipoPersona">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>Persona Moral/Empresa</FormOption>
                        <FormOption value={2}>Persona F&iacute;sica con Actividad Empresarial</FormOption>
                        <FormOption value={3}>Persona F&iacute;sica sin registro ante el SAT</FormOption> 
                    </FormSelect>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(3)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(3)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section4" show={show4}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormItemL appear={showT1}>¿Cuál es la Razón Social de la Empresa?</FormItemL>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL appear={showT2}>¿Cuál es el nombre de la Persona Física con Actividad Empresarial?</FormItemL>
                    <FormInput id="nombreEmpresa" placeholder={captionEmpresa} onBlur={(e) => Capitalize(e)}></FormInput>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(4)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(4)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section5" show={show5}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL appear={showT1}>¿Cuál es su RFC?</FormItemL>
                    <FormInput id="rfcEmpresa" placeholder={empresaRfc} onBlur={(e) => Capitalize(e)}></FormInput>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(5)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(5)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section6" show={show6}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL appear={showT1}>¿Cuál es la antigüedad del negocio?</FormItemL>
                <FormItemL appear={showT2}>¿Desde cuándo está la PFAE dada de alta bajo esa forma en el SAT?</FormItemL>
                    <FormSelect id="antiguedadNegocio">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>Menos de 2 años</FormOption>
                        <FormOption value={2}>De 2-5 años</FormOption>
                        <FormOption value={3}>De 5-10 años</FormOption>
                        <FormOption value={4}>M&aacute;s de 10 años</FormOption>
                    </FormSelect>
                    <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(6)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(6)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>


            <FormularioWrapper id="section7" show={show7}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL appear={showT1}>¿A qué sector pertenece la empresa?</FormItemL>
                <FormItemL appear={showT2}>¿A qué sector pertenece la PFAE?</FormItemL>
                    <FormSelect id="empresaSector"> 
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>Manufactura</FormOption>
                        <FormOption value={2}>Transporte y/o Logística</FormOption>
                        <FormOption value={3}>Comercialización</FormOption>
                        <FormOption value={4}>Construcción</FormOption>
                        <FormOption value={5}>Sector Energético</FormOption>
                        <FormOption value={6}>Sector Salud</FormOption>
                        <FormOption value={7}>Agroindustria y Agricultura</FormOption>
                        <FormOption value={8}>Hotelería y Restaurantes</FormOption>
                        <FormOption value={9}>Otros Servicios</FormOption>
                        <FormOption value={10}>Tecnología</FormOption>
                    </FormSelect>
                    <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(7)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(7)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section8" show={show8}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL>¿Quién es el principal cliente? </FormItemL>
                    <FormSelect id="principalCliente">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>PyMEs</FormOption>
                        <FormOption value={2}>Público en General</FormOption>
                        <FormOption value={3}>Gobierno</FormOption>
                        <FormOption value={4}>Transnacionales y AAA</FormOption>
                    </FormSelect>
                    <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(8)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(8)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section9" show={show9}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL>¿Cuáles son las ventas del último año?</FormItemL>
                    <FormSelect id="ventasTotales">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>De 0 a 30 MDP</FormOption>
                        <FormOption value={2}>De 30 a 50 MDP</FormOption>
                        <FormOption value={3}>De 50 a 100 MDP</FormOption>
                        <FormOption value={4}>De 100 a 250 MDP</FormOption>
                        <FormOption value={5}>De 250 a 500 MDP</FormOption>
                        <FormOption value={6}>M&aacute;s de 500 MDP</FormOption>
                    </FormSelect>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(9)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(9)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section10" show={show10}>
                <FormTitle appear={showT1}>Queremos conocer a la Empresa.</FormTitle>
                <FormTitle appear={showT2}>Queremos conocer a la PFAE.</FormTitle>
                <FormItemL>Ahora, cuéntanos de la Situación Actual de Buró de Crédito </FormItemL>
                    <FormSelect id="buroSituacion">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>No cuento con Historial Crediticio</FormOption>
                        <FormOption value={2}>Mis deudas se encuentran al corriente</FormOption>
                        <FormOption value={3}>Me he atrasado en pagos no mayores a 60 d&iacute;as</FormOption>
                        <FormOption value={4}>Tengo atrasos de pagos de m&aacute;s de 60 d&iacute;as</FormOption>
                    </FormSelect>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(10)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(10)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section11" show={show11}>
                <FormTitle>Queremos conocer tus necesidades.</FormTitle>
                <FormItemL>¿Estás buscando arrendamiento puro para un activo nuevo o para un lease back?</FormItemL>
                    <FormSelect id="tipoArrendamiento">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>Activo Nuevo</FormOption>
                        <FormOption value={2}>Lease Back</FormOption>
                    </FormSelect>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(11)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(11)}>Siguiente</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section12" show={show12}>
                <FormTitle>Queremos conocer tus necesidades.</FormTitle>
                <FormItemL>¿Qué antigüedad tiene el activo en el que buscas lease back?</FormItemL>
                    <FormSelect id="leaseBackAntiguedad">
                        <FormOption value={0}>Selecciona una opción</FormOption>
                        <FormOption value={1}>1 año &oacute; menos</FormOption>
                        <FormOption value={2}>2 años</FormOption>
                        <FormOption value={3}>3 años &oacute; m&aacute;s</FormOption>
                    </FormSelect>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(12)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(12)}>Terminar</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioWrapper id="section13" show={show13}>
                <FormTitle>Queremos conocer tus necesidades.</FormTitle>
                <FormItemL>Y por último, ¿Cuánto vale el activo?</FormItemL>
                    <FormInput id="valorActivo" placeholder='1,000,000.00' onChange={(e) => validate(e,3)}></FormInput>
                <FormSubText>MXN $0.00 con IVA*</FormSubText>
                <FormButton>
                    <ButtonSection>
                        <Button onClick={() => backward(13)}>Volver</Button>
                    </ButtonSection>    
                    <ButtonSection>
                        <Button primary dark onClick={() => forward(13)}>Terminar</Button>
                    </ButtonSection>
                </FormButton>
            </FormularioWrapper>

            <FormularioSplash id="section14" show={show14}>
                <FormTitleSplash>{nombre}, ¡Muchas gracias por proporcionarnos la información!</FormTitleSplash>
                <FormSubTitleSplash>Te enviaremos un correo a <BlackSubText>{correo}</BlackSubText> para indicarte los pasos siguientes.</FormSubTitleSplash>
                <FormButton>
                    <ButtonFinalSection>
                        <Button small onClick={() => backward(14)}>Vuelve a tivos^</Button>
                    </ButtonFinalSection>
                </FormButton>
                <FormSubTitleSplash style={{marginBottom:'0px'}}>¡Síguenos en nuestras redes!</FormSubTitleSplash>
                <SocialIconLink href="https://www.linkedin.com/company/tivos-capital/" target="_blank" aria-label="LinkedIn"><LinkedInWrapper><LinkedInIcon src={LinkedIn}/></LinkedInWrapper></SocialIconLink>
            </FormularioSplash>

        </FormularioContainer>
        <Footer />
        {/* </Body> */}
    </>
  )
}
export default PreRegistroForm 