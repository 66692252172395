//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//


//=====================================================
//    Funcion para llamar APIs externas
//=====================================================

    //    Constantes de conexion con Asana
    const pat = '1/1201383723071547:956e4cf31e38953eb1c12901082ba800'
    //const template = 'https://app.asana.com/api/1.0/project_templates/1202077476938119/instantiateProject' //Plantilla Anterior
    //const template = 'https://app.asana.com/api/1.0/project_templates/1202383091362413/instantiateProject' //Plantilla nueva 
    const template = 'https://app.asana.com/api/1.0/project_templates/1202527704994896/instantiateProject' //Plantilla nueva 29/06/2022
    const updateProject = 'https://app.asana.com/api/1.0/projects/'
    //const apiTivos = 'https://node.tivos.mx/'
    const apiTivos = process.env.REACT_APP_API
    const slackWebhook = process.env.REACT_APP_SLACKHOOK
    // const developmentEnv = true

    var headers = new Headers();


    headers.append('Content-type','application/json')
    headers.append('Authorization',`Bearer ${pat}`)


export function appExtConnLead(input,api,projectId,response){
    switch(api){
        case 'Slack':
            fetch('https://hooks.zapier.com/hooks/catch/11851902/bicoytp/',{
            // fetch(slackWebhook,{
                method : 'POST',
                body : input    
            })
            .then(response => response.json())
            .then(response('Se envio un mensaje al equipo para que este enterado.'))
            .catch(error => response(error))
            break;

        case 'ExA':
            fetch('https://hooks.zapier.com/hooks/catch/1508208/bi1xdkh/',{
                method : 'POST',
                body : input
            })
            .then(response => response.json())
            .then(response('Se creo un archivo en ExA.'))
            .catch(error => response(error))
            break;
        case 'Asa1':

            var request = {
                method      : 'POST',
                headers     : headers,
                body : input
            };

            fetch(template, request)
            .then(response => response.json())
            .then(data => response(data))
            .catch(error => response(error))

            break;
        case 'Asa2':

            request = {
                method : 'PUT',
                headers : headers,
                body : input
            }
            
            fetch(updateProject+projectId,request)
            .then(response => response.json())
            .then(response('Se creo un nuevo proyecto en Asana para que nuestro equipo pueda dar seguimiento.'))
            .catch(error => response(error))
            break;
        
        case 'Log':
        
            fetch('https://hooks.zapier.com/hooks/catch/11851902/bwiwha5/',{
                method : 'POST',
                body : input
            })
            .then(response => response.json())
            .then(response('Se ha creado un nuevo Log.'))
            .catch(error => response(error))
            break;
        
        case 'LogAsana':
        
            fetch('https://hooks.zapier.com/hooks/catch/11851902/blwweh4/',{
                method : 'POST',
                body : input
            })
            .then(response => response.json())
            .then(response('Se ha creado un nuevo Log de resumen.'))
            .catch(error => response(error))
            break;

        case 'InterB':

            // fetch('https://hooks.zapier.com/hooks/catch/11851902/bsk3pw8/',{
            //     method : 'POST',
            //     body : input
            // })
            // .then(response => response.json())
            // .then(response('Se ha creado un nuevo Lead en Intercom.'))
            // .catch(error => response(error))
            break;
        case 'InterC':

            // fetch('https://hooks.zapier.com/hooks/catch/11851902/bkw41nk/',{
            //     method : 'POST',
            //     body : input
            // })
            // .then(response => response.json())
            // .then(response('Se ha creado un nuevo Lead en Intercom.'))
            // .catch(error => response(error))
            break;
        case 'monday':
            fetch('https://hooks.zapier.com/hooks/catch/11851902/b7fk5zg/',{
                method : 'POST',
                body : input
            })
            .then(response => response.json())
            .then(response('Se ha creado un nuevo deal en Monday.'))
            .catch(error => response(error))        
            break;
        default:
            console.log('No hubo alguna accion.')
            break;

    }

}

//-----------------------------------------------------

//=====================================================
//  Function para conectar con APIs 
//=====================================================

export function tivosApi(data,response){
    var request = {
        method : 'POST',
        //mode: 'no-cors',
        headers : {
            'Content-Type' : 'application/json'
            },
        body : data,
    }
    fetch(apiTivos+'SaveInformation',request)
    .then(response => response.json())
    .then(result => response(result)//{
        // var data = JSON.parse(result.data[0])
        // data.forEach(element => {
        //     response(element)
        // });
        //console.log(data)
    )
    .catch(function(error){console.log('error',error)})

}

//=====================================================
//    Login process begins
//=====================================================

export function verifyLogin(data,response){
    var request = {
        method : 'POST',
        //mode: 'no-cors',
        headers : {
            'Content-Type' : 'application/json'
            },
        body : data,
    }

    fetch(apiTivos+'Verification',request)
    .then(response => response.json())
    .then(result => response(result)//{
        // var data = JSON.parse(result.data[0])
        // data.forEach(element => {
        //     response(element)
        // });
        //console.log(data)
    )
    .catch(function(error){console.log('error',error)})
}

//=====================================================
//    Login process begins
//=====================================================

export function savePassword(data,response){
    var request = {
        method : 'POST',
        //mode: 'no-cors',
        headers : {
            'Content-Type' : 'application/json'
            },
        body : data,
    }

    fetch(apiTivos+'SavePassword',request)
    .then(response => response.json())
    .then(result => response(result)//{
        // var data = JSON.parse(result.data[0])
        // data.forEach(element => {
        //     response(element)
        // });
        //console.log(data)
    )
    .catch(function(error){console.log('error',error)})
}

//=====================================================
