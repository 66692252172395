// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;700&family=Varela+Round&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*/=====================================================//\n//=====================================================//\n//                  tivos^.mx\n//  Author: David Rivera (dave@tivos.mx)\n//  Date: March 2022\n//  Description: Modulo general para conexiones internas y externas\n//\n//=====================================================//\n//  Changes Log:\n//  March 2022: Initial release.\n//\n//=====================================================/*/\n\n*{\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n    font-family: 'Varela Round','Encode Sans Expanded',sans-serif;\n}\n\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;yDAWyD;;AAIzD;IACI,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,6DAA6D;AACjE","sourcesContent":["/*/=====================================================//\n//=====================================================//\n//                  tivos^.mx\n//  Author: David Rivera (dave@tivos.mx)\n//  Date: March 2022\n//  Description: Modulo general para conexiones internas y externas\n//\n//=====================================================//\n//  Changes Log:\n//  March 2022: Initial release.\n//\n//=====================================================/*/\n\n@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;700&family=Varela+Round&display=swap');\n\n*{\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n    font-family: 'Varela Round','Encode Sans Expanded',sans-serif;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
