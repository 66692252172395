//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//


import styled from "styled-components";
import { Link as LinkS} from 'react-scroll'
import img from "../../images/bkg_light_hd.png";


export const BodyContainer = styled.div`
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 80px;
`;

export const BodyWrapper = styled.div`
    // padding 2% 5%;
`;

export const TopSection = styled.div`
    padding: 5%;
    margin-top: 50px;
`;

export const CuentasOKTitle = styled.p`
    font-size: 2.7em;
    margin-bottom: 5%;

    @media screen and (max-width: 1300px){
        font-size: 2.2em;
    }

    @media screen and (max-width: 900px){
        font-size: 2em;
    }

    @media screen and (max-width: 550px){
        font-size: 1.2em;
    }
    
`;

export const GreenTivos = styled.p`
    font-size: 2.5em;
    color: #1EB445;
`;

export const BlueCuentas = styled.p`
    font-size: 2.5em;
    color: #0ec2f0;
    float: left;
    margin: 0 10px;
`;

export const CuentasOKSubTitle = styled.p`
    font-size: 1.5em;
    color: #1EB445;
    margin-bottom: 5%;

    @media screen and (max-width: 550px){
        font-size: 1em;
        width: 100%;
    }

`;

export const ColumnLeft = styled.div`
    width: 50%;
    float: left;

    @media screen and (max-width: 550px){
        float: none;
        width: 300px;
        margin-left: 10%;
    }

`;

export const ColumnRight = styled.div`
    width: 45%;
    float: left;
    margin-left: 5%;

    @media screen and (max-width: 550px){
        float: none;
        width: 300px;
        margin-left: 10%;
    }

`;

export const LogoCOK = styled.img`
    width: 40%;
    margin-top: -10%;
    margin-right: 10%;
`;

export const LogoTivos = styled.img`
    width: 50%;
    margin-bottom: 5%;
`;

export const CuentasOkButton = styled(LinkS)`
    border-radius : 10px;
    background: #0ec2f0;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    border: solid 1px #0ec2f0;
    margin-top: 10px;
    margin-bottom: 10%;
    margin-left: ${({ marginL }) => (marginL ? '30%' : '40%')}};
    width: ${({ small }) => (small ? '50%': ({ medium }) => (medium ? '75%': ({ extraSmall }) => (extraSmall ? '25%': '100%')))};

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #0ec2f0;
        border: solid 1px #0ec2f0;
    }

    @media screen and (max-width: 1300px){
        width: 175px;
        margin-left: 30%;
    }

    @media screen and (max-width: 1050px){
        width: 175px;
        margin-left: ${({ marginL }) => (marginL ? '20%' : '30%')}};
    }

    @media screen and (max-width: 550px){
        width: 175px;
        margin-left: ${({ marginL }) => (marginL ? '20%' : '95%')}};
    }
`;

export const MiddleSection = styled.div`
    background-image: url(${img});
    background-size: cover;
    margin-top: 300px;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1300px){
        background-position: center;
    }

    @media screen and (max-width: 900px){
        background-position: center;
        background-size: cover;
        margin-top: 320px;
    }

    @media screen and (max-width: 550px){
        margin-top: 0px;
    }
`;

export const VideoButton = styled(LinkS)`
    border-radius : 10px;
    background: #1EB445;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    border: solid 1px #1EB445;
    margin-top: 10%;
    margin-bottom: 7%;
    width: 20%;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #1EB445;
        border: solid 1px #1EB445;
    }

    @media screen and (max-width: 1300px){
        //margin-left: -100%;
    }

    @media screen and (max-width: 900px){
        width: 30%;
    }

    @media screen and (max-width: 550px){
        width: 30%;
        margin-left: 0px;
        margin-top: 120px;
    }

`;

export const TivosSubTitle = styled.p`
    font-size: 2.2em;
    color: #1EB445;
    position: absolute;
    margin-top: -7%;
    
    @media screen and (max-width: 1300px){
        //margin-left: -50%;
    }

    @media screen and (max-width: 900px){
        font-size: 2em;
        margin-bottom: 10%;
    }

    @media screen and (max-width: 550px){
        width: 300px;
        margin-left: 0;
        font-size: 1.6em;
    }

`;

export const BottomSection = styled.div`
`;

export const BottomSectionTitle = styled.p`
    font-size: 2em;
    margin: 3%;

    @media screen and (max-width: 900px){
        font-size: 1.7em;
    }

    @media screen and (max-width: 550px){
        font-size: 1.2em;
    }

`;

export const BottomColumn = styled.div`
    width: 31%;
    float: left;
    padding: 5%;

    @media screen and (max-width: 1300px){
        padding: 5% 0% 5% 5%;
    }

    @media screen and (max-width: 1050px){
        padding: 3% 0 3% 0%;
    }

    @media screen and (max-width: 550px){
        float: none;
    }

`;

export const BottomImg = styled.img`
    margin: -10% 0 5% 22%;

    @media screen and (max-width: 1300px){
        margin-left: -10%;
    }

    @media screen and (max-width: 900px){
        width: 140%;
    }

    @media screen and (max-width: 550px){
        width: 275%;
        margin-left: 30%;
    }

`;

export const BottomColumnRight = styled.div`

`;

export const LabelColumnLeft = styled.div`
    font-size: 1em;
    text-align: center;
    margin: 15% 0% 0 20% ;

    @media screen and (max-width: 1300px){
        margin-left: 0%;
    }
    
    @media screen and (max-width: 1050px){
        width: 75%;
        margin-left: 7.5%;
    }

    @media screen and (max-width: 550px){
        font-size: 1.2em;
        width: 300px;
        display: none;
    }

`;

export const LabelColumnRight = styled.div`
    font-size: 1em;
    text-align: center;
    margin: 15% 0% 0 20% ;

    @media screen and (max-width: 1300px){
        margin-left: 25%;
    }

    @media screen and (max-width: 550px){
        font-size: 1.2em;
        width: 300px;
        display: none;
    }

`;

export const Video = styled.div`
    display: ${({ show }) => (show ? 'block' : 'none')}};
    position: absolute;
    z-index: 9999;
    width: 90%;
    height: 800px;
    top: 100px;
    left: 100px;
    background-color: rgba(42,178,75,.5);

    @media screen and (max-width: 900px){
        width: 90%;
        height: 400px;
        left: 35px;
    }

    @media screen and (max-width: 550px){
        width: 95%;
        height: 300px;
        left: 10px;
    }

`;

export const CloseButton = styled(LinkS)`
    position:relative;
    border-radius : 10px;
    background: #1EB445;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    border: solid 1px #1EB445;
    width: 10%;
    top: 101%;
    left: 45%;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #1EB445;
        border: solid 1px #1EB445;
    }
`;

export const Disclaimer = styled.p`
    position: absolute;
    margin-left: 7.5%;

    @media screen and (max-width: 1300px){
        margin-left: 0%;
    }

    @media screen and (max-width: 900px){
        margin-left: 0%;
    }

`;