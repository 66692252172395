//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//


import React, {useState} from 'react'
import ReactPlayer from 'react-player'

import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import Services from '../components/Services'
import Footer from '../components/Footer'
import Industry from '../components/Industry'
import Simulador from '../components/Simulador'
import Faqs from '../components/Faqs'
import Contacto from '../components/Contacto'
import {VideoPres, CloseButton} from '../components/HeroSection/HeroElements'

import ReactGA from 'react-ga';

//Set your APP_ID

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [show, setShow] = useState(false);
    const [mute, setMute] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const TRACKING_ID = 'G-B8GW7MWWCD';
    ReactGA.initialize(TRACKING_ID);
    
    const VideoStyles = {
        position:'absolute',
        width:'700px !important'
    }

    const showVideo = () => {
        if(!show){
            setShow(!show)
            if(!mute){
                setMute(mute)
            }
        }else{
            setShow(!show)
            setMute(!mute)
        };
    }

    return (
        <>
        <VideoPres show={!show} id='videoOver'>
            <ReactPlayer style={VideoStyles} height='90%' width='90%' url="https://www.youtube.com/watch?v=KrLOWCn0N6w&t=1s" controls={true} muted={mute} playing={!show} />
            <CloseButton onClick={showVideo}>Cerrar</CloseButton>
        </VideoPres>

          <Sidebar isOpen = {isOpen} toggle={toggle} />
          <Navbar toggle={toggle}/> 
          <HeroSection />
          <Services />
          <Industry />
          <Simulador />
          <Faqs />
          <Contacto />
          <Footer />
        </>
    )
}

export default Home
