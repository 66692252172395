//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import img from "../../images/bkg_1_mod.png";
import { Link } from 'react-router-dom';

export const GlobalStyle = createGlobalStyle`
    body{
        overflow-x: hidden;
        background-image: url(${img});
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
    }
    
    @media screen and (max-width: 900px){
        body{
            width: 50%;
        }
    }

    @media screen and (max-width: 500px){
        body{
            overflow-x: hidden !important;
        }
    }
    
`;


export const LoginContainer = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-size: cover;

    @media screen and (max-width: 900px){
        padding-top: 30%;
        margin-left: 100%;
    }

    @media screen and (max-width: 700px){
        padding: 50% 5%;
    }

`;

export const LoginWrapper = styled.div `
    background: white;
    margin: 10%;
    padding: 2.5% 3.5%;
    border-radius: 5px;
    filter: ${({ blur }) => ( blur ? 'blur(2px)':'none')};
    display: grid;
    height: 600px;
    margin-right: auto;
    margin-left: auto;
    padding: 34px 24px;
    justify-content: center;
    
    @media screen and (max-width: 1100px){
        width: 450px;
        height: 550px;
    }

    @media screen and (max-width: 480px){
        width: 380px;
        height: 500px;
      }


`;

export const LoginRow = styled.div `
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';

    @media screen and (max-width: 1000px){
        height: 500px;
      }

`;

export const LoginColumn1 = styled.div `
    width: 500px;
    height: 500px;
    grid-area: col1;
`;

export const LoginColumn2 = styled.div `
    width: 500px;
    height: 500px;
    grid-area: col2;

    @media screen and (max-width: 1100px){
        display: none;
    }
    
`;

export const LoginInfo = styled.div `
    padding: 2.5%  15%;
    margin-bottom: 10%;
    
    @media screen and (max-width: 1100px){
        margin-left: 10%;
    }
   
    @media screen and (max-width: 480px){
        padding-top: 0%;
    }
`;

export const Logo = styled.img `
    width: 80%;
    padding: 10%;
`;

export const Label = styled.h3`
    color: #1EB445;
    margin-bottom: 3%;
    letter-spacing: 1px;
`;

export const Input = styled.input `
    border: 2px solid #e6e6e6;
    height: 3em;
    width: 75%;
    margin-bottom: 5%;
    padding: 10px;

    ::placeholder{
        color: #828282;
        font-style: italic; 
    }

    &:focus{
        outline: none;
        border: none;
        box-shadow: 0 0 5px #1EB445;
    }
`;

export const ForgotPassword = styled.a`
    color: #1EB445;
    font-size: .7em;
    font-weight: 600;
    width: 50%;
    margin-left: 35%;
    cursor: pointer;
`;

export const Terms = styled.a`
    color: #1EB445;
    font-weight: 600;
    margin-left: 15%;
    cursor: pointer;
`;

export const LoginImage = styled.img `
    width: 100%;
`;

export const FormPopup = styled.div`
    display:${({ show }) => ( show ? 'block':'none')};
    height: auto;
    width: 450px;
    background-color: #fff;
    position: absolute;
    top: 300px;
    border: 2px solid #1EB445;
    border-radius: 5px;
    z-index: 99;

    @media screen and (max-width: 1100px){
        width: 400px;
    }

`;

export const PopupTitle = styled.h2`
    margin-top: 5%;
    text-align: center;
    color: #1EB445;

    @media screen and (max-width: 1100px){
        font-size: 1.2em;
    }

`;

export const PopupMessage = styled.p`
    margin-top: 5%;
    padding: 2%;
    text-align: center;
    font-size: 1em;
`;

export const ButtonFinalSection = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 4%;
`;

export const FormButton = styled.div `
    margin-top: 30px;
`;

export const FormItemLSub = styled.div `
    font-size: 1em;
    margin-top: -5%;
    margin-left: -15%;
    text-align: center;

    @media screen and (max-width: 1100px){
        margin-left: -5%;
    }
`;

export const FormLinkPage = styled(Link)`
    color: #1EB445;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 1em;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
        transition: 0.3s ease-out;
    }
`;

export const FormCheckBox = styled.input `
    margin-right: 10px;
    color: #01bf71;
`;
